<template>
  <div class="brand-info">
    <div class="brand-info__slogan">
      <div class="seo-content__title" v-html="dataSeo?.content_title" />
      <div class="seo-content__description" v-html="dataSeo?.content_description" />
    </div>
    <div v-if="$device.isMobileOrTablet" class="list-reserve">
      <nuxt-link
        to="//www.dmca.com/Protection/Status.aspx?ID=f362511c-0d21-4b76-af37-f633d9a377ba"
        title="DMCA.com Protection Status"
        class="dmca-badge"
        target="_blank"
      >
        <BaseImg :src="`${PATH_FOOTER_URL}dmca.svg`" alt="DMCA.com Protection Status" />
      </nuxt-link>
      <BaseImg :src="`${PATH_FOOTER_URL}copyright-logo.webp`" class="reserve-image" alt="copy right" />
    </div>
    <div v-if="$device.isMobileOrTablet" class="copy-right">
      {{ FOOTER_CONTENT.COPY_RIGHT }}
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { PATH_FOOTER_URL, PATH_IMAGES } from '~/constants/path'
import { footerContentData } from '@/constants/footer'
import { useAppStore } from '~/store/app'
const FOOTER_CONTENT = footerContentData()
const { $device } = useNuxtApp()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { seoData } = storeToRefs(store)
const route = useRoute()

const dataSeo = computed(() => {
  if (route.path === '/') {
    return seoData.value.find((item) => item.alias === '/')
  }
  if (route.name === 'khuyen-mai-id') {
    return seoData.value.find((item) => item.alias === 'khuyen-mai')
  }
  return seoData.value.find((item) => item.alias === route.path.slice(1)) ?? seoData.value.find((item) => item.alias === '/')
})

</script>

<style scoped lang="scss" src="public/assets/scss/components/common/brand-info.scss" />
