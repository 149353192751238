<template>
  <div class="banner-item cheat">
    <BaseImg
      :relative-src="`${PATH_CHEAT_URL}hero-banner-${device}.webp`"
      :alt="ALT_IMAGE.HERO_BANNER"
    />
  </div>
</template>
<script setup lang="ts">
import { PATH_CHEAT_URL } from '~/constants/path'
import { ALT_IMAGE } from '~/constants/common'
defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})
const { $device } = useNuxtApp()
const device = $device.isMobile ? 'mb' : 'pc'
</script>
<style scoped src="./index.scss" />
