<template>
  <div
    class="hero-banner"
    :class="{ mobile: isMobile }"
  >
    <Swiper
      ref="banner"
      v-bind="swiperOption"
      class="mySwiper"
    >
      <SwiperSlide
        v-for="item in bannerData"
        :key="item.name"
        class="swiper-slide"
        @click="handleClickBanner(item)"
      >
        <CommonHeroBannerBannerItem :item="item" />
      </SwiperSlide>
    </Swiper>
  </div>

  <CommonBaseEmpty
    v-if="emptyData"
    class="center w-100"
    :empty-data="EMPTYDATA"
  />
</template>
<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, EffectFade, Navigation } from 'swiper'
import CommonHeroBannerBannerItem from '@/components/common/hero-banner/banner-item'
import { EMPTYDATA } from '~/constants/home'
import 'swiper/css/effect-fade'
import useHeroBanner from '~/composables/home/useHeroBanner'
import CommonBaseEmpty from '@/components/common/base-empty'

const { fetchBanner, handleClickBanner } = useHeroBanner()

const { $device } = useNuxtApp()
const isMobile = $device.isMobileOrTablet
defineProps({
  isLoading: {
    type: Boolean,
    default: false
  },
  emptyData: {
    type: Boolean,
    default: false
  }
})
const swiperOption = isMobile
  ? reactive({
    loop: false,
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    navigation: true,
    modules: [Autoplay, Navigation]
  })
  : reactive({
    loop: false,
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    effect: 'fade',
    navigation: true,
    modules: [Autoplay, Navigation, EffectFade]
  })

const { data: bannerData } = await useAsyncData('banner', async () => {
  return await fetchBanner()
})
</script>
<style scoped src="./index.scss" />
