<template>
  <div class="home">
    <MobilePagesHomeSectionBanner />
    <MobilePagesHomeSectionTypeBet />
    <div class="home__container">
      <Stream />
      <MobilePagesHomeSectionQuaySo />
      <SectionGameBai />
      <LazyHydrationWrapper when-visible @on-loading-game="loadingGame">
        <MobilePagesHomeSectionTopPlay />
      </LazyHydrationWrapper>
      <LazyHydrationWrapper when-visible>
        <SectionIntroduce />
        <SectionContact />
        <CommonBrandInfo />
      </LazyHydrationWrapper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LazyHydrationWrapper } from 'vue3-lazy-hydration'
import SectionContact from './section-contact/index.vue'
import MobilePagesHomeSectionBanner from './section-banner/index.vue'
import MobilePagesHomeSectionTypeBet from './section-type-bet/index.vue'
import MobilePagesHomeSectionQuaySo from './section-quay-so/index.vue'
import MobilePagesHomeSectionTopPlay from './section-top-play/index.vue'
import Stream from '~/components/mobile/pages/home/stream/index.vue'
import SectionGameBai from '~/components/mobile/pages/home/section-game-bai/index.vue'
import { useAppStore } from '~/store/app'
import CommonBrandInfo from '~/components/common/brand-info/index.vue'
const SectionIntroduce = defineAsyncComponent(() => import('./section-introduce/index.vue'))
const isLoading = ref(true)
const { isBotChromeLighthouse } = useAppStore()

const loadingGame = (loading) => {
  setTimeout(() => {
    isLoading.value = loading
  }, 500)
}

useHead({
  bodyAttrs: {
    class: 'mobile-custom'
  }
})
</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/index.scss"></style>
