<template>
  <section class="section-banner">
    <CommonHeroBannerCheat v-if="isBotChromeLighthouse" />
    <CommonHeroBannerBannerDesktop v-else />
  </section>
</template>
<script setup lang="ts">
import CommonHeroBannerBannerDesktop from '~/components/common/hero-banner/banner-desktop'
import CommonHeroBannerCheat from '~/components/common/hero-banner/banner-item/cheat.vue'
import { useAppStore } from '~/store/app'
const { isBotChromeLighthouse } = useAppStore()
</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/section-banner/index.scss"></style>
