<template>
  <div class="support-container">
    <SectionTitle title="Liên hệ" />
    <div class="support">
      <div
        v-for="item in contacts"
        :id="`support-${item?.name}`"
        :key="item.name"
        class="support__item"
        :class="[item?.type, item?.className]"
      >
        <div v-if="item.isLiveChat" class="support__item--link" @click="openLiveChat()">
          <BaseImg v-if="!isBotChromeLighthouse" class="icon icon-guide" :relative-src="item.img" :alt="item.name" />
          <div class="text">{{ item.display_name }}</div>
        </div>
        <div v-else>
          <NuxtLink :to="item.url" target="_blank" class="support__item--link">
            <BaseImg v-if="!isBotChromeLighthouse" class="icon" :relative-src="item.img" :alt="item.name" />
            <p class="text">{{ item.display_name }}</p>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import SectionTitle from '~/components/mobile/common/section-title.vue'
import { useAppStore } from '~/store/app'

const { isBotChromeLighthouse } = useAppStore()
const { fetchBranchContact, openLiveChat } = useContact()
const { data: contacts } = await useAsyncData('contacts', async () => {
  return await fetchBranchContact()
})
</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/section-contact/index.scss"></style>
